<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-1">
                <div class="col-12 section_title uppercase">Crear Orden de Compra</div>
              </div>
            </template>
  
            <template v-slot:end>
              <Button
                label="GUARDAR"
                icon="pi pi-save"
                class="p-button-success mr-2"
                @click="save()"
              />
            </template>
          </Toolbar>
  
  
          <div class="">
            <div class="formgrid grid">
                <div class="field col-4">
                  <label for="base">Proveedor</label>
                  <AutoComplete
                    v-model="selectedClient"
                    :suggestions="filteredClients"
                    @complete="searchClient($event)"
                    field="name"
                    :dropdown="true"
                    @item-select="newClient = selectedClient"
                    id="base"
                    class="inputfield w-full"
                    forceSelection 
                  />
  
  <Button
    :disabled="selectedClient"
    @click="newClient()"
    label="Nuevo Proveedor"
    icon="pi pi-plus"
    class="mt-1 inputfield p-button-sm p-button-info"
  />
                </div>
                 <div class="field col-2">
                  <label for="formu">Moneda</label>
                  <SelectButton v-model="form.moneda" :options="monedas"  optionLabel="name" />
   
                </div>  
          
                <div class="field col-2">
                  <label for="fecha_ingreso">Fecha Ingreso</label>
                    <Calendar
                        id="fecha_ingreso"
                        v-model="form.fecha_ingreso"
                        class="inputfield w-full"
                        :showIcon="true"
                        
                    />
                </div>   
   <div class="w-1  ">
                
              </div>  
              <div class="field col-2">
                  <label for="p_dolar">Precio U$D</label>
                  <InputNumber
                    mode="decimal"
                    :minFractionDigits="2"
                    v-model="this.p_dolar"
                    id="p_dolar"
                    
                    class="inputfield w-full"
                  />
                  <div>
                    <small> BNA: {{ this.dolar_suggested }}</small>
                  </div>
                </div>

                <div class="field col-2">
                <label for="formu">Envío</label>
                <SelectButton v-model="form.envio" :options="[{ value: 0, name: 'Si'},{ value: 1, name: 'No'}]"  optionLabel="name" />
              </div> 
              <div class="field col-2">
                <label for="entrega">Lugar de Entrega </label>
                
                <InputText id="entrega" v-model="form.entrega"  class="inputfield w-full" />
   
              </div> 
  
     
              <div class="field col-3">
                  <label for="fdp">Forma de pago</label>
                               <InputText id="formu" v-model="forma_pago"  class="inputfield w-full" />
   
                </div>  
  
              <div class="field col-12">
                <label for="formu">Observación</label>
                <InputText id="formu" v-model="form.observacion" class="inputfield w-full" />
              </div>
  
  
  
  
          </div>  
          </div>  
  
        </div>
      </div>
      <div class="col-12">
  
  
              
        <div class="card">
          <div class="my-1">
            <div class="col-12 section_subtitle">Items <small> </small></div>
          </div>
  
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
                <label for="base">Código</label>
                <!-- <AutoComplete
                  v-model="selectedProduct"
                  :suggestions="filteredProducts"
                  @complete="searchProduct($event)"
                  field="name"
                  :dropdown="true"
                  :value="name"
                  @item-select="newItem = null; valorToAdd = selectedProduct.last_price"
                  @item-unselect="newItem = null; "
                  id="base"
                  class="inputfield w-full"
                />  -->
                  <InputText
                    v-model="material_code"
                    id="formu"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
              </div>
              <div class="field col-2">
                <label for="base">Detalle</label>
                <!-- <AutoComplete
                  v-model="selectedProduct"
                  :suggestions="filteredProducts"
                  @complete="searchProduct($event)"
                  field="name"
                  :dropdown="true"
                  :value="name"
                  @item-select="newItem = null; valorToAdd = selectedProduct.last_price"
                  @item-unselect="newItem = null; "
                  id="base"
                  class="inputfield w-full"
                />  -->
                  <InputText
                    v-model="selectedProduct"
                    id="formu"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
              </div>
              <div class="field col-2">
                  <label for="formu">Cantidad</label>
                  <InputText
                   :disabled="!selectedProduct"
                    v-model="qtyToAdd"
                    id="formu"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
              </div>
              
              <div class="field col-2">
                <label for="formu">Unidad</label>
                  <!-- UNIDAD -->
                  <Dropdown
                    v-model="unidad"
                    :disabled="!selectedProduct"
                    :options="unidades"
                    placeholder="Unidad"
                    class="inputfield w-full"
                  />
              </div>
              <div class="field col-2">
                  <label for="formu w-full">Valor</label>
                  <InputText
                   :disabled="!selectedProduct"
                    v-model="valorToAdd"
                    id="formu"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
              </div>
              <div class="field col-1  ">
                <div class="relative w-3rem h-4rem mx-3 my-3 md:my-0 border-round">
              <div class="absolute bottom-0 left-0 text-white font-bold flex align-items-center justify-content-center border-round">
                
  <Button v-if="selectedProduct && selectedProduct.id" @click="editPiece(selectedProduct.id)"  
                icon="pi pi-pencil" class="p-button-sm p-button-info" />
                </div>
  
              </div>
          </div>
                <div class="flex flex-wrap align-items-center justify-content-center">
  
                
              </div> 
  
  
          <div class="field col-2">
          
              <Button :disabled="!qtyToAdd"  @click="addItem(newItem)" label="Agregar" 
                icon="pi pi-plus" class="p-button-success inputfield w-full mt-5" />
  
          </div>
  
  
  
            </div>
          </div>
  
          <div class="">
            <DataTable
              :value="items"
               dataKey="id" 
              class="p-datatable-sm"
              responsiveLayout="scroll"
            >
              
              <Column header="" >
                <template #body="slotProps">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="delItem(slotProps.data)"></Button>
  
                    <Button v-if="slotProps.data.plane" icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                    <!-- <div v-for="plane in slotProps.data.planos" :key="plane.id">
                       <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                    </div> -->
                </template>
              </Column>
              
             <Column headerClass="" field="material_code" header="Código" headerStyle="max-width: 20%">
                <template #body="slotProps">
                  <InputText 
                    v-model="slotProps.data.material_code"
                    id="material_code"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
                </template>
              </Column>
             <Column headerClass="" field="qty" header="Cantidad" headerStyle="max-width: 20%">
                <template #body="slotProps">
                  <InputText 
                    v-model="slotProps.data.qty"
                    id="iqty"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
                </template>
              </Column>
              <Column headerClass="" field="unidad" header="Unidad" headerStyle="max-width: 20%">
                <template #body="slotProps">
                  <Dropdown
                    v-model="slotProps.data.unidad"
                    :options="unidades"
                    placeholder="Unidad"
                    class="inputfield w-full"
                  />
                </template>
              </Column>
              <Column field="name" header="Detalle">
              <template #body="slotProps" >
                <InputText 
                    v-model="slotProps.data.name"
                    id="imp"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
              </template>
              </Column>
  
  
              <Column field="fest" header="Fecha prevista">
              
                <template #body="slotProps" >
              <InputText
                  id="entrega"
                  v-model="slotProps.data.fest"
                  class="inputfield w-full"
                />
  </template>
              </Column>
  
              <Column field="valor" header="Valor">
              
              <template #body="slotProps">
                      <div class="flex justify-content-between">
                            <InputText
                    v-model="slotProps.data.valor"
                    id="formu"
                    type="text"
                    class="inputfield w-full"
                    placeholder=""
                  />
  
                        <!-- <div>
                          <span>{{ toSelectedValue(slotProps.data.valor) }}</span>
                        </div> -->
                        <!-- <div>
                          <span>{{toArs(valorByClientType(slotProps.data.valor) )}}</span>
                        </div> -->
                      </div>
                  
                  </template>
  
              </Column>
               <!-- <Column field="unidad" header="Unidad"></Column> -->
              <Column header="Subtotal">
                  <template #body="slotProps">
                      <div class="flex justify-content-between">
                        <div>
                          <span>{{toSelectedValue(slotProps.data.valor * slotProps.data.qty)}}</span>
                        </div>
                      </div>
                  
                  </template>
              ></Column>
            </DataTable>
  
          </div>
        </div>
  
        <div class="card">
          <div class="mx-1">
            <div class="col-4 section_subtitle p-ml-auto">Totales <small> </small></div>
          </div>
  
  
            <DataTable
              :value="calculos"
              dataKey="id" 
              class="p-datatable-sm"
              responsiveLayout="scroll"
            >
              <Column field="name" header="Detalle"></Column>
              <Column field="valor" exportableheader="Subtotal">
                  <template #body="slotProps">
                      <div class="flex justify-content-between">
                        <div>
                          <span>{{toSelectedValue(slotProps.data.valor)}}</span>
                        </div>
                      </div>
                  
                  </template>
              ></Column>
  
  
  
            </DataTable>
  
  
        </div>  
  
  
      </div>
  
      <Dialog
        v-model:visible="showModalNewClient"
        :style="{ width: '50%' }"
        header="Nuevo proveedor"
        :modal="true"
        class="p-fluid"
      >
        <ModalNewProvider @add_client="add_client" />
      </Dialog>
  
    </div>
  </template>
  
  <script>
  import CrudService from  "./../../../services/crud.service";
  import ModalNewProvider from "./../prov/new_modal.vue";
  
  export default {
    data() {
      return {
        p_dolar: 1,
        dolar_suggested: 1,
        ocNumber: "",
        monedas: [{ value: 0, name: 'AR$'},{ value: 1, name: 'U$D' }],
        impuestos: [{ value: 0, name: '0%'},{ value: 0.21, name: '21%'},{ value: 0.105, name: '10.5%'}],
        routeName: null,
        clientType: {value: 0, name: 'Consumidor Final'},
        configuraciones: [],
        qtyToAdd: 1,
        valorToAdd: 1,
        material_code: "",
        selectedFdp: null,
        form: { 
          fecha_ingreso: new Date().toISOString().substr(0, 10),
          impuesto: {value: 0.21, name: '21%'},
          envio: {value: 0, name: 'Si'},
          moneda: {value: 0, name: 'AR$'},
          observacion: ' PRUEBA ',
          
  
        }, 
        unidad: 'Unidad',
        unidades: [
          'Unidad',
          'Kg',
          'Mts',
          'Lts',
          'M2',
          'M3',
  
        ],
        items: [],
        forma_pago: "",
        actualFormula: "",
        products: null,
        filteredProducts: null,
        newItem: null,
        selectedProduct: null,
  
        Clients: null,
        filteredClients: null,
        newItem: null,
        selectedClient: null,
  
        Budgets: null,
        filteredBudgets: null, 
        selectedBudget: null,
  
        stock: null,
        filteredStock: null, 
        
  
        mods: null,
        newItemMOD: null,
        filteredMod: null,
        selectedMod : null,
        actualFormulaMOD: "",
        
        modsMarco: null,
        newItemMODMarco: null,
        filteredModMarco: null,
        selectedModMarco : null,
        actualFormulaMODMarco: "",
  
        submitted: false,
        validationErrors: null,
   
        ciif: 0,
        mpi: 0,
        moi: 0 ,
        //calcQuantity: 0,
   
        formasdepago: [ 
        ],
        materials: [],
        showModalNewClient: false,
  
      };
    },
    components: {
      ModalNewProvider,
    },
    computed: {
      dolarValue() {
        switch (this.form.moneda.value) {
          case 1:
            return this.configuraciones.dolar_billete;
          case 3:
            return this.configuraciones.dolar_divisa; 
          default:
            return this.configuraciones.dolar_billete;
        }
  
      },
      totalItems() {
        
        let total = 0;
  
        this.items.forEach(item => {
          console.log(item)
          total += item.valor * item.qty;
        });
  
        return total;
  
      },
      totalMOD() {
        
        let total = 0;
  
        this.mod.forEach(item => {
          console.log(item)
          total += item.valor * item.qty;
        });
  
        return total;
  
      },
      calculos() {
  // Costo de Produccion
  // MPI
  // MOI
  // CIIF
  // Costo de General
  
        let data = 
        [
          {
            name: "Subtotal",
            valor: this.totalItems
          },
         /* {
            name: "IVA",
            valor: this.mpi
          },
          {
            name: "Otros Impuestos",
            valor: this.moi
          },
          {
            name: "Total",
            valor: this.ciif
          },*/
          // {
          //   name: "Costo de General",
          //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
          // },
        ];
  
   
      
        if(this.form.descuento > 0) {
          // to negative
          data.push({
            name: "Descuento",
            valor: this.totalItems * (-this.form.descuento / 100)
          })
        }
  
        if(this.form.impuesto.value > 0) {
  
          let descuento = 0;
          if(this.form.descuento > 0) {
            descuento = this.totalItems * (-this.form.descuento / 100);
          }
  
  
          data.push({
            name: "Impuesto",
            valor: (this.totalItems + descuento) * this.form.impuesto.value
          })
        }
  
  // sum all data valor
        let total = 0;
        data.forEach(item => {
          total += item.valor;
        });
  
        data.push({
          name: "Total",
          valor: total
        });
        return data
  
        
      },
  
    },
    created() {
      // get route name
      this.routeName = this.$route.name;
    },
    mounted() {
      CrudService.getCRUD("api2a/proveedor").then(
        (data) => (this.clients = data)
      );
      CrudService.getCRUD("api/costos")
        .then((data) => {
          //console.log(data.bna.dolar.value)
          this.dolar_suggested = parseInt(data.bna.dolar.value).toFixed(2);
  
          this.p_dolar = parseFloat(data.bna.dolar.value);
        })
        .then(() => {
          this.loaded = true;
        });
    },
    methods: {
      newClient() {
        this.selectedClient = null;
        this.showModalNewClient = true;
      },
      add_client(data) {
        //console.log(data)
        this.clients.push(data.cliente);
        this.selectedClient = data.cliente;
        this.showModalNewClient = false;
      },
  
      costoProd(){
  
        let costo = 0;
  
        return costo;
  
      },
      toArs(value) {
   
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS",
        });
      },
      toUsd(value) {
        
        // format number to currency
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "USD",
        });
  
      },
      toSelectedValue(value){
  
        if(this.form.moneda.value == 0){
          return this.toArs(value)
        }else{
          return this.toUsd(value)
        }
        
      },
      showPlane(file) {
        
        // open window
        window.open(file, "_blank");
        
        
  
      },
  delItem(itemId){
    //console.log(item)
    // find in this.items
    let index = this.items.findIndex(item => item.id == itemId);
    // remove item
    this.items.splice(index, 1);
  
    // recalculate total
  
  
  }, 
        formatUnity(value) {
  
             switch(value) {
                  case 0:
                      return "ml";
                  case 1:
                      return "unidad";
                  case 2:
                      return "kg";
                  case 3:
                      return "mt";
                  case 4:
                      return "lt";
                  case 5:
                      return "m2";
                  case 6:
                      return "mtl";
                  case 7:
                      return "gr";
                  case 8:
                      return "juego";
                  default:
                      return "";
              }
        },
      displayErrors(errors) {
        for (let key in errors) {
          this.validationErrors.push({ field: key, message: errors[key] });
  
          let error = errors[key];
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error[0],
            life: 3000,
          });
        }
      },
      searchStock(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredStock = [...this.stock];
          } else {
            this.filteredStock = this.stock.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchProduct(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredProducts = [...this.products];
          } else {
            this.filteredProducts = this.products.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchClient(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredClients = [...this.clients];
          } else {
            this.filteredClients = this.clients.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      
      searchBudgets(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredBudgets = [...this.Budgets];
          } else {
            this.filteredBudgets = this.Budgets.filter((prod) => {
              return prod.id == event.query || prod.client_name.toLowerCase().includes(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchMDO(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredMod = [...this.mods];
          } else {
            this.filteredMod = this.mods.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      addItem() {
   
   
  
            this.items.push({
              id: 0,
              name: this.selectedProduct,
              qty: this.qtyToAdd,
              valor: this.valorToAdd,
              unidad: this.unidad,
              material_code: this.material_code
            });
  
  
          this.newItem = null;
          this.qtyToAdd = 1;
          this.valorToAdd = 1;
          this.selectedProduct = null;
   
      },
      save() {
        this.submitted = true;
   
        let formData = new FormData();
  
        if (this.selectedClient == null) {
          this.$toast.add({
            severity: "error",
  
            //summary: "Error!",
            summary: "Debe seleccionar un proveedor!",
            life: 3000,
          });
          return;
        } else {
          formData.append("proveedor", this.selectedClient.id);
        }
  
  
        formData.append("fecha", this.form.fecha_ingreso);
        formData.append("observacion", this.form.observacion);
        //formData.append("fecha_entrega", this.form.fecha_entrega);
        formData.append("dolar_value", this.p_dolar);
  
        formData.append("moneda", this.form.moneda.value);
        formData.append("impuesto",this.form.impuesto.value);
  
        formData.append("descuento",this.form.descuento);
        if(this.forma_pago){
          formData.append("forma_pago", this.forma_pago);
        }
        formData.append("envio",this.form.envio.value);
        formData.append("items", JSON.stringify(this.items));
  
        CrudService.createCRUD("api2a", "compras", formData).then(
          (data) => {
            if (data.status == "success") {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Creada correctamente.",
              life: 3000,
            });
  
            this.$router.push("/2a/compras");
          } else {
            console.log(data);
            // forech error.data.errors
            for (var key in data.data.errors) {
              if (data.data.errors.hasOwnProperty(key)) {
                this.$toast.add({
                  severity: "error",
                  //summary: "Error!",
                  summary: data.data.errors[key][0],
                  life: 3000,
                });
              }
            }
          }
  
          }
        ).catch((error) => {
          console.log(error);
        });
  
  
      },
    },
  };
  </script>
  
  
  <style scoped>
  
    .section_subtitle {
      font-size: 1.2rem;
      font-weight: bold;
      color: #1261b1;
      margin-bottom: 1rem;
    }
  
    .card {
      padding-top: 0.25rem;
    }
  
  </style>